import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Path } from "../context";

const NotFound = function () {
	const path = useContext(Path);
	let origin = window.location.origin;
	return (
		<div id="NotFound">
			<h2>Sorry we couldn't find that scripture.</h2>
			<a href={origin}>Go Back</a>
		</div>
	);
};

export default NotFound;
