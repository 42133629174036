import React from "react";
import { Route } from "react-router-dom";
import App from "./App";
import { setVersion } from "./services";

const Version = function (props) {
	let version = setVersion(props.history);
	return (
		<React.Fragment>
			<App {...props} version={version} />
		</React.Fragment>
	);
};

export default Version;
