import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Volume as VolumeRef, Book, Path } from "../../context";
import style from "../../styles/components/Volume.module.css";
import content from "../../assets/content.json";

const Volume = function () {
	// context
	const volumeRef = useContext(VolumeRef);
	const book = useContext(Book);
	const path = useContext(Path);

	let params = new URLSearchParams(window.location.search);
	let version = params.get("version");

	// create selectors
	let selectors;
	if (book) {
		// create chapter list
		selectors = book.chapters.map(function (v) {
			return (
				<Link
					to={"/" + v.path.name + path.version}
					key={v.id}
					className={style.selector}
				>
					{content.chapter[version]} {v.number}
				</Link>
			);
		});
	} else {
		// create books list
		selectors = volumeRef.books.map(function (v) {
			return (
				<Link
					to={"/" + v.path.name + "/" + 1 + path.version}
					key={v.id}
					className={style.selector}
				>
					{v.title}
				</Link>
			);
		});
	}

	return (
		<div className={style.Volume}>
			<div className={style.selectorWrap}>{selectors}</div>
		</div>
	);
};

export default Volume;
