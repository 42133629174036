import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { VolumeList, Volume, Path } from "../../context";

import style from "../../styles/components/Home.module.css";

// assets
import otPic from "../../assets/ot-banner.jpg";
import ntPic from "../../assets/nt-banner.jpg";
import bmPic from "../../assets/bm-banner.jpg";
import dcPic from "../../assets/dc-banner.jpg";
import pgpPic from "../../assets/pgp-banner.jpg";

const Home = function () {
	const volumeList = useContext(VolumeList);
	const path = useContext(Path);
	const pics = [otPic, ntPic, bmPic, dcPic, pgpPic];

	let volumes = volumeList.map(function (v, i) {
		return (
			<Link
				key={v.id}
				to={"/" + v.path.name + path.version}
				className={style.volume}
			>
				<img alt={v.title} src={pics[i]} />
				<div className={style.overlay}></div>
				<h2 className={style.title}>{v.titleLong}</h2>
			</Link>
		);
	});

	return (
		<div className={style.Home}>
			<div className={style.volumeList}>{volumes}</div>
		</div>
	);
};

export default Home;
