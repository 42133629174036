import React, { useState, useEffect } from "react";
import { Path, VolumeList, Volume } from "./context";
import { apiCall } from "./services";
import { ErrorBoundary } from "./hocs";
// containers
import Title from "./containers/Title";
import Main from "./containers/Main";
// components
import Loading from "./components/Loading";
import NotFound from "./components/NotFound";

function App(props) {
	const [loading, setLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [volumeList, setVolumeList] = useState(null);
	const [volume, setVolume] = useState(null);
	const [path, setPath] = useState({
		version: props.version,
		location: props.location,
		history: props.history,
		name: props.location.pathname.split("/").slice(1).join("/"),
		volume: props.location.pathname.split("/")[1] || null,
		book: props.location.pathname.split("/")[2] || null,
		chapter: props.location.pathname.split("/")[3]
			? props.location.pathname.split("/")[3].split(".")[0]
			: null,
		verse: props.location.pathname.split("/")[3]
			? props.location.pathname.split("/")[3].split(".")[1]
			: null,
	});

	useEffect(
		function () {
			setPath({
				version: props.version,
				location: props.location,
				history: props.history,
				name: props.location.pathname.split("/").slice(1).join("/"),
				volume: props.location.pathname.split("/")[1] || null,
				book: props.location.pathname.split("/")[2] || null,
				chapter: props.location.pathname.split("/")[3]
					? props.location.pathname.split("/")[3].split(".")[0]
					: null,
				verse: props.location.pathname.split("/")[3]
					? props.location.pathname.split("/")[3].split(".")[1]
					: null,
			});
		},
		[props.location.pathname]
	);

	// adjust scrolling on navigation
	useEffect(
		function () {
			if (document.querySelector("#App")) {
				let app = document.querySelector("#App");
				if (app.scrollTop >= 300) {
					app.scrollTop = 300;
				} else if (app.scrollTop < 300) {
					if (typeof app.scrollTo === "function") {
						app.scrollTo({ top: 0, left: 0, behavior: "smooth" });
					} else {
						app.scrollTop = 0;
					}
				}
			}
		},
		[path]
	);

	useEffect(function () {
		setLoading(true);
		apiCall("" + props.version, "GET")
			.then(function (res) {
				setVolumeList(res);
				setLoading(false);
			})
			.catch(function (err) {
				console.log(err);
			});
	}, []);

	useEffect(
		function () {
			if (path.volume) {
				setLoading(true);
				apiCall(path.volume + props.version, "GET")
					.then(function (res) {
						setVolume(res);
						setNotFound(false);
						setLoading(false);
					})
					.catch(function () {
						setNotFound(true);
					});
			} else {
				setVolume(null);
				setNotFound(false);
			}
		},
		[path.volume]
	);

	if (notFound) {
		return <NotFound />;
	}

	return (
		// <ErrorBoundary>
		<VolumeList.Provider value={volumeList}>
			<Path.Provider value={path}>
				<Volume.Provider value={volume}>
					{volumeList ? (
						<div id="App" className="fade-in">
							<Title path={path} volumeList={volumeList} />
							<Main
								path={path}
								volumeList={volumeList}
								volume={volume}
								loading={loading}
								setLoading={setLoading}
							/>
						</div>
					) : (
						<Loading />
					)}
				</Volume.Provider>
			</Path.Provider>
		</VolumeList.Provider>
		// </ErrorBoundary>
	);
}

export default App;
