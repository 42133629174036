import React, {useState} from 'react'
import Loading from '../components/Loading'


export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      window.location.replace('https://scriptures.greaterhope.today')
    }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Loading />

    }

    return this.props.children 
  }
}


