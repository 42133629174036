import React, { useState, useEffect, useContext } from "react";
import style from "../styles/containers/Nav.module.css";
import { asideToggle } from "../services/index";
import { Volume } from "../context";
import content from "../assets/content.json";

// components
import Volumes from "../components/nav/Volumes";
import Books from "../components/nav/Books";

function Nav(props) {
	const volume = useContext(Volume);
	const [mobile, setMobile] = useState(
		window.matchMedia("(max-width: 900px)").matches
	);
	let params = new URLSearchParams(window.location.search);
	let version = params.get("version");

	// set mobile change
	const WidthChange = function (mq) {
		setMobile(mq.matches);
	};
	const mq = window.matchMedia("(max-width: 900px)");
	mq.addListener(WidthChange);

	// handlers
	const handleClose = function (e) {
		asideToggle();
	};

	return (
		<aside id="Nav" className={style.Nav}>
			<div className={style.headerWrap} onClick={handleClose}>
				<h3 className={style.header}>{content.scriptures[version]}</h3>
				<h3 className={style.close}>X</h3>
			</div>
			<Volumes volume={volume} />
			<hr />
			{volume && <Books mobile={mobile} />}
		</aside>
	);
}

export default Nav;
