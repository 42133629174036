import React, { useContext, useState, useEffect } from "react";
import style from "../../styles/containers/Content.module.css";
import { Path, Volume, Book, Chapter } from "../../context";
import { apiCall } from "../../services";

const Nav = function () {
	// context
	const path = useContext(Path);
	const volume = useContext(Volume);
	const book = useContext(Book);
	const chapter = useContext(Chapter);
	// state
	const [prev, setPrev] = useState(null);
	const [next, setNext] = useState(null);

	useEffect(function () {
		if (chapter) {
			setPrev(chapter.prev);
			setNext(chapter.next);
		} else if (book) {
			setPrev(book.prev);
			setNext(book.next);
		} else if (volume) {
			setPrev(volume.prev);
			setNext(volume.next);
		}
	});

	const handleClick = function (e) {
		apiCall(
			`id/${e.target.id === "next" ? next : prev}${path.version}`,
			"GET"
		).then(function (res) {
			path.history.push("/" + res.path.name + path.version, res.id);
		});
	};

	return (
		<div id="contentNav" className={style.Nav}>
			{prev && (
				<div id="prev" onClick={handleClick} className={style.prev}>
					<div>&lt;</div>
				</div>
			)}
			{next && (
				<div id="next" onClick={handleClick} className={style.next}>
					<div>&gt;</div>
				</div>
			)}
		</div>
	);
};

export default Nav;
