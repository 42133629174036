import React, { useState, useEffect, useContext } from "react";
import style from "../styles/containers/Main.module.css";
import { apiCall } from "../services";
import { Book, Chapter, Path } from "../context";
// containers
import Body from "./Body";
import Nav from "./Nav";
// components
import NotFound from "../components/NotFound";

function Main(props) {
	const [book, setBook] = useState(null);
	const [chapter, setChapter] = useState(null);
	const [notFound, setNotFound] = useState(false);
	const path = useContext(Path);

	useEffect(
		function () {
			if (props.path.book) {
				props.setLoading(true);
				apiCall(`${props.path.volume}/${props.path.book}${path.version}`, "GET")
					.then(function (res) {
						setBook(res);
						props.setLoading(false);
					})
					.catch(function () {
						setNotFound(true);
					});
			} else {
				setBook(null);
			}
		},
		[props.path.book]
	);

	useEffect(
		function () {
			if (props.path.chapter) {
				props.setLoading(true);
				apiCall(
					`${props.path.volume}/${props.path.book}/${props.path.chapter}${path.version}`,
					"GET"
				)
					.then(function (res) {
						setChapter(res);
						props.setLoading(false);
					})
					.catch(function () {
						setNotFound(true);
					});
			} else {
				setChapter(null);
			}
		},
		[props.path]
	);

	if (notFound) {
		return <NotFound />;
	}

	return (
		<Book.Provider value={book}>
			<Chapter.Provider value={chapter}>
				<div id="Main" className={style.Main}>
					<Body loading={props.loading} />
					<Nav volume={props.volume} />
				</div>
			</Chapter.Provider>
		</Book.Provider>
	);
}

export default Main;
