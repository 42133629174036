import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import style from "../styles/containers/Content.module.css";
import { Path, Chapter as ChapterRef, Volume as VolumeRef } from "../context";

// components
import Home from "../components/content/Home";
import Volume from "../components/content/Volume";
import Chapter from "../components/content/Chapter";
import Nav from "../components/content/Nav";
import Loading from "../components/Loading";

const Content = function (props) {
	const path = useContext(Path);
	const volumeRef = useContext(VolumeRef);
	const chapterRef = useContext(ChapterRef);

	if (props.loading) {
		return <Loading />;
	}

	return (
		<div key={path.name} className="fade-in">
			{path.name.length > 1 && <Nav />}
			<div className={style.Content}>
				<Switch>
					<Route
						exact
						path="/"
						render={function () {
							return <Home />;
						}}
					/>
					<Route
						exact
						path="/doctrine_and_covenants"
						render={() => {
							return <Redirect to="/doctrine_and_covenants/section" />;
						}}
					/>
					<Route
						exact
						path="/:volume"
						render={
							volumeRef &&
							function () {
								return <Volume />;
							}
						}
					/>
					<Route
						exact
						path="/:volume/:book"
						render={
							volumeRef &&
							function () {
								return <Volume />;
							}
						}
					/>
					<Route
						exact
						path="/:volume/:book/:chapter"
						render={
							chapterRef &&
							function () {
								return <Chapter />;
							}
						}
					/>
				</Switch>
			</div>
		</div>
	);
};

export default Content;
