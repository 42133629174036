import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "../styles/containers/Toolbar.module.css";
import { asideToggle } from "../services/index";
import { Intro, Volume, Book, Chapter, Path } from "../context";

// components

function Toolbar() {
	// context
	const volume = useContext(Volume);
	const book = useContext(Book);
	const chapter = useContext(Chapter);
	const path = useContext(Path);

	const reference = function () {
		if (chapter) {
			if (book && book.chapters.length === 1) {
				return (
					<h4 className={style.reference}>
						{chapter.title.substring(0, chapter.title.length - 1).trim()}
					</h4>
				);
			}
			return <h4 className={style.reference}>{chapter.title}</h4>;
		} else if (book) {
			return (
				<h4 className={style.reference}>
					{book.title === "Section" ? "Sections" : book.title}
				</h4>
			);
		} else if (volume) {
			return <h4 className={style.reference}>{volume.title}</h4>;
		} else {
			return <h4 className={style.reference}>{"Scriptures"}</h4>;
		}
	};
	// handlers
	const handleClick = function () {
		asideToggle();
	};

	return (
		<div className={style.Toolbar}>
			<Link to={"/" + path.version} className={style.home}>
				<i className="fas fa-home"></i>
			</Link>
			{reference()}
			<div className={style.asideToggle} onClick={handleClick}>
				<i className="fas fa-book-open"></i>
			</div>
		</div>
	);
}

export default Toolbar;
