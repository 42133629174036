import React from 'react'
import style from '../styles/containers/Body.module.css'
// containers
import Toolbar from './Toolbar'
import Content from './Content'

function Body(props){
	return(
		<div id="Body" className={style.Body}>
			<Toolbar />
			<Content loading={props.loading} />
		</div>
	)
}

export default Body