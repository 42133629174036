import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "../../styles/containers/Nav.module.css";
import { VolumeList, Path } from "../../context";

function Volumes(props) {
	// context
	const volumeList = useContext(VolumeList);
	const path = useContext(Path);

	// handlers
	const handleVolumeSelect = function (e) {
		const prevSelection = document.querySelector('[class*="Nav_activeVolume"]');
		if (prevSelection) {
			prevSelection.classList.toggle(style.activeVolume);
		}
		e.currentTarget.classList.toggle(style.activeVolume);
	};

	// returned components
	let volumes = volumeList.map(function (v) {
		if (props.volume && props.volume.id === v.id) {
			// change behavior for volumes containing only one book
			let short = v.books.length === 1 ? v.books[0] : null;

			return (
				<Link
					to={"/" + path.version}
					key={v.id}
					id={v.id}
					className={`${style.volume} ${
						props.volume && props.volume.id === v.id && style.activeVolume
					}`}
					onClick={handleVolumeSelect}
				>
					{v.title}
				</Link>
			);
		} else {
			return (
				<Link
					to={"/" + v.path.name + path.version}
					key={v.id}
					id={v.id}
					className={`${style.volume} ${
						props.volume && props.volume.id === v.id && style.activeVolume
					}`}
					onClick={handleVolumeSelect}
				>
					{v.title}
				</Link>
			);
		}
	});

	return <React.Fragment>{volumes}</React.Fragment>;
}

export default Volumes;
