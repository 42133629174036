import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import style from "../../styles/containers/Nav.module.css";
import { Volume, Book, Path } from "../../context";

// components
import Chapters from "./Chapters";

function Books(props) {
	const volume = useContext(Volume);
	const book = useContext(Book);
	const path = useContext(Path);

	// returned components
	let books = volume.books.map(function (v) {
		if (book && book.id === v.id) {
			// change behavior for books containing only one chapter
			let short = book.chapters.length === 1 ? book.chapters[0] : null;

			let pathArr = v.path.name.split("/");
			let newPath = pathArr.slice(0, pathArr.length - 1).join("/");

			return (
				<React.Fragment>
					<Link
						to={"/" + newPath + path.version}
						key={v.id}
						className={`${style.book} ${style.activeBook}`}
					>
						{v.title === "Section" ? "Sections" : v.title}
						<span></span>
					</Link>
					{short ? (
						<Redirect to={`/${book.path.name}/1${path.version}`} />
					) : null}
					{book && !short && (
						<Chapters book={book} mobile={props.mobile} currentBook={v.id} />
					)}
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<Link
					to={"/" + v.path.name + path.version}
					key={v.id}
					className={style.book}
				>
					{v.title === "Section" ? "Sections" : v.title}
					<span></span>
				</Link>
				{book && (
					<Chapters book={book} mobile={props.mobile} currentBook={v.id} />
				)}
			</React.Fragment>
		);
	});

	return <React.Fragment>{books}</React.Fragment>;
}

export default Books;
