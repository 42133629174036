import mainStyle from "../styles/containers/Main.module.css";
import contentStyle from "../styles/containers/Content.module.css";
import bodyStyle from "../styles/containers/Body.module.css";
import navStyle from "../styles/containers/Nav.module.css";

// api call function
export const apiCall = function (path, method, reqBody) {
	return new Promise((resolve, reject) => {
		fetch(`https://scri-315115.uk.r.appspot.com/${path}`, {
			method: method,
			body: JSON.stringify(reqBody),
		})
			.then((res) => {
				if (!res.ok) {
					throw Error(res);
				}
				return res.json();
			})
			.then((res) => {
				return resolve(res);
			})
			.catch((err) => {
				console.log(err);
				return reject(err);
			});
	});
};

// aside toggler
export const asideToggle = function () {
	document.getElementById("Nav").classList.toggle(navStyle.openAside);
	document.getElementById("Main").classList.toggle(mainStyle.openAside);
	document.getElementById("Body").classList.toggle(bodyStyle.openAside);
};

export const setVersion = function (history) {
	let params = new URLSearchParams(window.location.search);
	let version = params.get("version");
	if (!version) {
		version = "standard-eng";
		history.push(`?version=${version + window.location.hash}`);
	}
	return `?version=${version}`;
};
