import React, { useContext } from "react";
import { Link } from "react-router-dom";
import style from "../../styles/containers/Nav.module.css";
import { asideToggle } from "../../services";
import content from "../../assets/content.json";

import { Chapter, Path } from "../../context";

// components

function Chapters(props) {
	const chapter = useContext(Chapter);
	const path = useContext(Path);
	let params = new URLSearchParams(window.location.search);
	let version = params.get("version");

	const handleClose = function () {
		asideToggle();
	};

	// returned components
	let chapters = props.book.chapters.map(function (v) {
		if (v.id.includes(props.currentBook)) {
			let title =
				v.path.volume === "doctrine_and_covenants" ? "Section" : "Chapter";
			if (chapter && chapter.id === v.id) {
				let pathArr = v.path.name.split("/");
				let newPath = pathArr.slice(0, pathArr.length - 1).join("/");
				return (
					<Link
						to={"/" + newPath + path.version}
						className={`${style.chapter} ${style.activeChapter}`}
						key={v.id}
					>
						{title} {v.number}
					</Link>
				);
			} else {
				return (
					<Link
						to={"/" + v.path.name + path.version}
						key={v.id}
						onClick={props.mobile ? handleClose : null}
						className={style.chapter}
					>
						{title} {v.number}
					</Link>
				);
			}
		}
	});

	return <React.Fragment>{chapters}</React.Fragment>;
}

export default Chapters;
