import React, { useContext, useState, useEffect } from "react";
import style from "../../styles/components/Chapter.module.css";
import content from "../../assets/content.json";

import { Chapter as ChapterRef, Path } from "../../context";

const Chapter = function () {
	const chapter = useContext(ChapterRef);
	const path = useContext(Path);
	const [nums, setNums] = useState(null);

	let params = new URLSearchParams(window.location.search);
	let version = params.get("version");

	useEffect(
		function () {
			if (path.location.hash) {
				let hashIndex = Number(path.location.hash.split("v")[1]);
				if (hashIndex > 1) {
					let ref = document.getElementById("v" + (hashIndex - 1));
					ref.scrollIntoView({ behavior: "smooth" });
				}
			}
		},
		[path.location.hash, chapter]
	);

	useEffect(
		function () {
			if (path.verse) {
				let sets = path.verse.split(",");
				let numbers = [];
				sets.forEach(function (v) {
					if (v.includes("-")) {
						let index = v.split("-");
						let start = Number(index[0]);
						let end = Number(index[1]);
						for (var i = 0; i + start <= end; i++) {
							numbers.push(start + i);
						}
					} else {
						numbers.push(Number(v));
					}
				});
				setNums(numbers);
			}
		},
		[path.verse]
	);

	let verses = chapter.verses.map(function (v) {
		return (
			<div
				key={v.id}
				className={`${style.verse} ${
					nums && nums.includes(v.number) && style.activeVerse
				}`}
			>
				<p id={"v" + v.number}>
					<span>{v.number} </span>
					{v.text}
				</p>
			</div>
		);
	});

	return (
		<div key={chapter.id} className={style.Chapter}>
			<div className={style.container}>
				<h3>
					{content.chapter[version]} {chapter.number}
				</h3>
				<div className={style.container}>{verses}</div>
			</div>
		</div>
	);
};

export default Chapter;
