import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./styles/index.css";

import Version from "./Version.js";

ReactDOM.render(
	<Router>
		<Route path="/" component={Version} />
	</Router>,
	document.getElementById("root")
);
