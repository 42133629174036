import React, { useEffect, useState } from "react";
import style from "../styles/containers/Title.module.css";
// components

// assets
import introPic from "../assets/scriptures-banner.jpg";
import otPic from "../assets/ot-banner.jpg";
import ntPic from "../assets/nt-banner.jpg";
import bmPic from "../assets/bm-banner.jpg";
import dcPic from "../assets/dc-banner.jpg";
import pgpPic from "../assets/pgp-banner.jpg";

function Title(props) {
	// picture, volume
	// if no volume use intro pic and text
	const [volume, setVolume] = useState(null);
	const [pic, setPic] = useState(null);

	useEffect(
		function () {
			setVolume(
				props.volumeList.find(function (v) {
					return props.path.volume === v.path.volume;
				})
			);
		},
		[props.path.volume]
	);

	useEffect(
		function () {
			if (volume) {
				switch (volume.id) {
					case "ot":
						setPic(otPic);
						break;
					case "nt":
						setPic(ntPic);
						break;
					case "bm":
						setPic(bmPic);
						break;
					case "dc":
						setPic(dcPic);
						break;
					case "pgp":
						setPic(pgpPic);
						break;
					default:
				}
			} else {
				setPic(introPic);
			}
		},
		[volume]
	);

	return (
		<div className={style.Title}>
			<img className={style.background} src={pic} alt="page title" />
			<div className={style.overlay}></div>
			<div className={style.textContainer}>
				<div className={style.textWrap}>
					<div key={volume && volume.id} className={style.textWrap2}>
						<h1>{volume ? volume.titleLong : "The Scriptures"}</h1>
						<h2>{volume ? volume.titleSub : null}</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Title;
